import React, { Suspense, useContext } from "react";

import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import $ from "jquery"
import "react-toastify/dist/ReactToastify.css";
import "./assets/styles/custom.css";
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";
// import  "./assets/js/autoptimize_bd13ca4f5dbbdb0b48a0b8b855b16d97";
// import  "./assets/js/autoptimize_d8a8f6f1d288606b558d5aa1015da51f";
// import  "./assets/js/autoptimize_f3cf82afaea8b089aaa613fea7ac0cb1";

import  "./assets/styles/mainstyle.css"; 
// import  "./assets/js/auto";




import Loader from "./components/Loader";
import ScrollToTop from "./components/ScrollToTop";
import { AppContextProvider } from "./context/AppContext";
import { AuthContext, AuthProvider } from "./context/AuthContext"; 
import Home from "./pages/Auth/Home"; 
import PrivateRoute from "./components/PrivateRoute";
import Error from "./pages/Error"; 
import ExchangeIndex from "./pages/Currency_Rate/ExchangeDetail";

window.Buffer = window.Buffer || require("buffer").Buffer;
function App() {
  return (
    <AuthProvider>
      <AppContextProvider>
        <Suspense fallback={<Loader />}>
          <BrowserRouter>
            <ScrollToTop />
            <ToastContainer closeOnClick={false} />
            <AppRoutes />
          </BrowserRouter>
        </Suspense>
      </AppContextProvider>
    </AuthProvider>
  );
}

const AppRoutes = () => {
  const { isLoggedIn } = useContext(AuthContext);

  return (
    <Routes>
      <Route path="/" element={<Home />} />  
      <Route path="/exchange" element={<ExchangeIndex />} />  

      <Route
        path="/"
        element={
          <PrivateRoute>
            <Outlet />
          </PrivateRoute>
        }
      >
        {/* <Route path="/dashboard" element={<HomeIndex />} />
        <Route path="/wallet" element={<WalletIndex />} />  */}

      </Route>

      <Route path="/error" element={<Error />} />
    </Routes>
  );
};

export default App;
