import {
  Card,
  Col,
  Form,
  Row,
  Select,
  Table,
  Modal,
  Button,
  Input,
  Tag,
  Progress,
  Tooltip,
} from "antd";
import { ClockCircleOutlined } from "@ant-design/icons";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import apiPath from "../../constants/apiPath";
import flag from "../../assets/images/bahrain.png";
import RateIcon from "../../assets/images/rate_head_icon.png";
import Flag from "react-world-flags";
import useRequest from "../../hooks/useRequest";
import { RightOutlined } from "@ant-design/icons";

const { Option } = Select;

function BannerIndex() {
  const { request } = useRequest();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [selectedCurrency, setSelectedCurrency] = useState("");
  const [baseCurrency, setBaseCurrency] = useState("USD");
  const [isBuy, setIsBuy] = useState(false);
  const [amount, setAmount] = useState(0);
  const [convertedAmount, setConvertedAmount] = useState(0);
  const [conversionRate, setConversionRate] = useState(0);
  const [reverseAmount, setReverseAmount] = useState(0);
  // const [pagination, setPagination] = useState({
  //   page: 1,
  //   pageSize: 10,
  //   total: 0,
  // });

  const handleCurrencyChange = (value) => {
    setSelectedCurrency(value);
    let flagAmount = data.find((item) => item?.currency === value);
    console.log(flagAmount);
    setConversionRate(flagAmount?.buy);
    setConvertedAmount(amount * flagAmount?.buy);
    setReverseAmount(amount / flagAmount?.buy);
  };

  const handleBaseCurrencyChange = (value) => {
    setBaseCurrency(value);
    let flagAmount = data.find((item) => item?.currency === value);
    console.log(flagAmount);
    setConversionRate(flagAmount?.buy);
    setConvertedAmount(amount * flagAmount?.buy);
    setReverseAmount(amount / flagAmount?.buy);
  };

  const handleAmountChange = (e) => {
    let value = parseInt(e.target.value || 0);
    setAmount(value);
    setConvertedAmount(value * conversionRate);
    setReverseAmount(value / conversionRate);
  };

  const getForexData = () => {
    request({
      url: apiPath.common.forexPrice + `?currency=${baseCurrency}`,
      method: "GET",
      onSuccess: (data) => {
        console.log(data);
        let final = data?.data?.filter((item) => {
          return ["USD", "AED", "EUR", "THB", "HKD", "JPY"].includes(
            item?.currency
          );
        });
        setData(final);
      },
      onError: (err) => {
        console.log(err);
      },
    });
  };

  useEffect(() => {
    getForexData();
  }, [baseCurrency]);

  const columns = [
    {
      title: `CURRENCY / ${baseCurrency}`,
      dataIndex: "currency",
      key: "currency",
      render: (text, { currency }) => {
        let code = currency?.substring(0, 2);
        return (
          <>
            <Flag code={code} style={{ width: "32px", marginRight: "8px" }} />
            <span>{currency}</span>
          </>
        );
      },
    },
    {
      title: "BUYING",
      dataIndex: "buying",
      key: "buying",
      render: (_, { buy }) => {
        return <span>{buy?.toFixed(4)}</span>;
      },
    },
    {
      title: "SELLING",
      dataIndex: "selling",
      key: "selling",
      render: (_, { sell }) => {
        return <span>{sell?.toFixed(4)}</span>;
      },
    },
  ];

  return (
    <>
      <section className="elementor-section elementor-top-section elementor-element elementor-element-43b14e3 elementor-section-full_width elementor-section-height-default elementor-section-height-default">
        <div className="elementor-container elementor-column-gap-no">
          <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-4bef19f">
            <div className="elementor-widget-wrap elementor-element-populated">
              <div
                className="elementor-element elementor-element-0660807 elementor-widget elementor-widget-ashley-hero-banner"
                data-id={""}
                data-element_type="widget"
                data-widget_type="ashley-hero-banner.default"
              >
                <div className="elementor-widget-container">
                  {/* banner */}
                  <section className="mil-banner mil-dark-bg">
                    <div className="mi-invert-fix">
                      <div className="mil-animation-frame">
                        <div
                          className="mil-animation mil-position-1 mil-scale"
                          style={{
                            translate: "none",
                            rotate: "none",
                            scale: "none",
                            transform:
                              "translate3d(0px, 0px, 0px) scale(2.6477, 2.6477)",
                          }}
                        >
                          <div className="mil-dodecahedron">
                            {Array.from({ length: 12 }).map((_, index) => (
                              <div key={index} className="mil-pentagon">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                              </div>
                            ))}
                          </div>
                        </div>

                        <div
                          className="mil-animation mil-position-2 mil-scale"
                          style={{
                            translate: "none",
                            rotate: "none",
                            scale: "none",
                            transform:
                              "translate3d(0px, 0px, 0px) scale(1.3442, 1.3442)",
                          }}
                        >
                          <div className="mil-dodecahedron">
                            {Array.from({ length: 12 }).map((_, index) => (
                              <div key={index} className="mil-pentagon">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                              </div>
                            ))}
                          </div>
                        </div>

                        <div
                          className="mil-animation mil-position-3 mil-scale"
                          style={{
                            translate: "none",
                            rotate: "none",
                            scale: "none",
                            transform:
                              "translate3d(0px, 0px, 0px) scale(0.8651, 0.8651)",
                          }}
                        >
                          <div className="mil-dodecahedron">
                            {Array.from({ length: 12 }).map((_, index) => (
                              <div key={index} className="mil-pentagon">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                      <div className="container">
                        <div className="main-slaide">
                          <Row>
                            <Col span={24} lg={12}>
                              <div className="mil-banner-content mil-up hero-slaider">
                                <h1 className="el-title mil-muted mil-mb-30">
                                  {" "}
                                  <span>
                                    Trade
                                    <span className="mil-thin"> Smarter,</span>
                                    <br /> Grow
                                    <span className="mil-thin"> Faster</span>
                                  </span>
                                </h1>
                                <div className="row">
                                  <div className="col-md-7 col-lg-8">
                                    <p className="el-text mil-light-soft mil-mb-60">
                                      <span>
                                        Join our platform and trade with
                                        confidence. Real-time data, low fees,
                                        and powerful tools to maximize your
                                        investment potential.
                                      </span>
                                    </p>
                                  </div>
                                </div>
                                {/* buttons */}{" "}
                                <a
                                  href="services/index.html"
                                  className="mil-button mil-arrow-place mil-btn-space"
                                >
                                  <span> What we do </span>{" "}
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    class="mil-arrow"
                                  >
                                    {" "}
                                    <path d="M 14 5.3417969 C 13.744125 5.3417969 13.487969 5.4412187 13.292969 5.6367188 L 13.207031 5.7226562 C 12.816031 6.1136563 12.816031 6.7467188 13.207031 7.1367188 L 17.070312 11 L 4 11 C 3.448 11 3 11.448 3 12 C 3 12.552 3.448 13 4 13 L 17.070312 13 L 13.207031 16.863281 C 12.816031 17.254281 12.816031 17.887344 13.207031 18.277344 L 13.292969 18.363281 C 13.683969 18.754281 14.317031 18.754281 14.707031 18.363281 L 20.363281 12.707031 C 20.754281 12.316031 20.754281 11.682969 20.363281 11.292969 L 14.707031 5.6367188 C 14.511531 5.4412187 14.255875 5.3417969 14 5.3417969 z"></path>{" "}
                                  </svg>
                                </a>{" "}
                                s
                                <div className="mil-circle-text">
                                  <svg
                                    version="1.1"
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                    x="0px"
                                    y="0px"
                                    viewBox="0 0 300 300"
                                    enableBackground="new 0 0 300 300"
                                    xmlSpace="preserve"
                                    className="mil-ct-svg mil-rotate"
                                    data-value={360}
                                  >
                                    <defs>
                                      <path
                                        id="circlePath"
                                        d="M 150, 150 m -60, 0 a 60,60 0 0,1 120,0 a 60,60 0 0,1 -120,0 "
                                      />
                                    </defs>
                                    <circle
                                      cx={150}
                                      cy={100}
                                      r={75}
                                      fill="none"
                                    />
                                    <g>
                                      <use
                                        xlinkHref="#circlePath"
                                        fill="none"
                                      />
                                      <text style={{ letterSpacing: "6.5px" }}>
                                        {/* circle text */}
                                        <textPath xlinkHref="#circlePath">
                                          Scroll down - Scroll down -
                                        </textPath>
                                      </text>
                                    </g>
                                  </svg>
                                  <a
                                    href="#about"
                                    aria-label="Scroll down - Scroll down - "
                                    class="mil-button mil-arrow-place mil-icon-button mil-arrow-down"
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                      class="mil-arrow"
                                    >
                                      <path d="M 14 5.3417969 C 13.744125 5.3417969 13.487969 5.4412187 13.292969 5.6367188 L 13.207031 5.7226562 C 12.816031 6.1136563 12.816031 6.7467188 13.207031 7.1367188 L 17.070312 11 L 4 11 C 3.448 11 3 11.448 3 12 C 3 12.552 3.448 13 4 13 L 17.070312 13 L 13.207031 16.863281 C 12.816031 17.254281 12.816031 17.887344 13.207031 18.277344 L 13.292969 18.363281 C 13.683969 18.754281 14.317031 18.754281 14.707031 18.363281 L 20.363281 12.707031 C 20.754281 12.316031 20.754281 11.682969 20.363281 11.292969 L 14.707031 5.6367188 C 14.511531 5.4412187 14.255875 5.3417969 14 5.3417969 z"></path>
                                    </svg>
                                  </a>
                                </div>
                              </div>
                            </Col>
                            <Col span={24} lg={12}>
                              <div className="currency-exchange-container">
                                <div className="auto-currency-header">
                                  <Select
                                    value={baseCurrency}
                                    className="select-currncy-topbar"
                                    placeholder="Select Base Currency"
                                    onChange={handleBaseCurrencyChange}
                                  >
                                    {["USD", "SGD", "HKD", "THB"].map(
                                      (item) => {
                                        return (
                                          <Option key={item} value={item}>
                                            {item}
                                          </Option>
                                        );
                                      }
                                    )}
                                  </Select>
                                </div>
                                <div class="rates-header">
                                  <img src={RateIcon} class="vertical-middle" />
                                  <h6 class="text-binding">RATE UPDATE </h6>
                                </div>
                                <div className="currency-table">
                                  <Table
                                    columns={columns}
                                    dataSource={data}
                                    pagination={false}
                                    bordered
                                  />
                                </div>
                                <div className="table-fts">
                                  <Button
                                    className="all-rate-btn"
                                    onClick={() =>
                                      navigate("/exchange", {
                                        state: { data: baseCurrency },
                                      })
                                    }
                                  >
                                    <div className="right-erroe">
                                      <RightOutlined />{" "}
                                    </div>{" "}
                                    View all currencies
                                  </Button>
                                </div>
                                <div className="currency-converter">
                                  <h3>GET CURRENCY</h3>
                                  <Select
                                    className="select-currncy"
                                    placeholder="Select Currency"
                                    onChange={handleCurrencyChange}
                                  >
                                    {data?.map((item) => {
                                      return (
                                        <Option value={item?.currency}>
                                          {item?.currency}
                                        </Option>
                                      );
                                    })}
                                  </Select>

                                  {selectedCurrency && (
                                    <span style={{ color: "orange" }}>
                                      1 {selectedCurrency}{" "}
                                      &nbsp;&nbsp;&nbsp;&nbsp; =
                                      &nbsp;&nbsp;&nbsp;&nbsp;{" "}
                                      {(1 / conversionRate)?.toFixed(4)} {baseCurrency}
                                    </span>
                                  )}

                                  <Row style={{ marginTop: 10 }} gutter={16}>
                                    <Col span={12}>
                                      <div className="currancy-cjad">
                                        <Button
                                          onClick={() => setIsBuy(true)}
                                          type="primary"
                                          className="rate-btns"
                                        >
                                          BUYING{" "}
                                        </Button>
                                        <span>
                                          Foreign Currencies to {baseCurrency}
                                        </span>
                                      </div>
                                    </Col>
                                    <Col span={12}>
                                      <div className="currancy-cjad">
                                        <Button
                                          onClick={() => setIsBuy(false)}
                                          type="primary"
                                          className="rate-btns"
                                        >
                                          SELLING{" "}
                                        </Button>
                                        <span>
                                          {baseCurrency} to Foreign Currencies
                                        </span>
                                      </div>
                                    </Col>
                                  </Row>

                                  <div className="conversion-section">
                                    <p>How much would you like?</p>
                                    <Row
                                      gutter={16}
                                      className="align-items-center"
                                    >
                                      <Col span={11}>
                                        <Input
                                          className="add-innput"
                                          value={amount}
                                          onChange={handleAmountChange}
                                          addonAfter={
                                            isBuy
                                              ? selectedCurrency
                                              : baseCurrency
                                          }
                                          type="number"
                                        />
                                      </Col>
                                      {selectedCurrency && (
                                        <>
                                          <Col span={1} className="equals-sign">
                                            =
                                          </Col>
                                          <Col span={11}>
                                            <span>
                                              {(isBuy
                                                ? reverseAmount
                                                : convertedAmount
                                              )?.toFixed(4)}{" "}
                                              {isBuy
                                                ? baseCurrency
                                                : selectedCurrency}
                                            </span>
                                          </Col>
                                        </>
                                      )}
                                    </Row>
                                  </div>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </div>
                  </section>
                  {/* banner end */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default BannerIndex;
