import React, { useEffect, useState } from "react";
import { Card, Form, Input } from "antd";
import { useAppContext } from "../context/AppContext";
import useRequest from "../hooks/useRequest";
import { Severty, ShowToast } from "../helper/toast";
import apiPath from "../constants/apiPath";

const ContactUsIndex = () => {
  const { request } = useRequest();
  const [info, setInfo] = useState({
    cf_name: "",
    cf_email: "",
    cf_message: "",
  });

  const handleChange = (e) => {
    setInfo((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  };

  const handleSubmit = () => {
    const payload = {
      name: info.cf_name,
      email: info.cf_email,
      message: info.cf_message,
    };

    if(!payload.email || !payload.message || !payload.name){
      ShowToast("Please enter all details for support!", Severty.ERROR);
      return;
    }

    request({
      url: apiPath.support,
      method: "POST",
      data: { ...payload },
      onSuccess: (data) => {
        if (data?.statusText == "true") {
          ShowToast(data?.message, Severty.SUCCESS);
          setInfo({
            cf_name: "",
            cf_email: "",
            cf_message: "",
          });
        } else {
          ShowToast(data?.message, Severty.ERROR);
        }
      },
      onError: (err) => {
        ShowToast(err?.message, Severty.ERROR);
      },
    });
  };

  return (
    <>
      <section
        className="elementor-section elementor-inner-section elementor-element elementor-element-2ab12e8 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
        id="about"
      >
        <div className="elementor-container elementor-column-gap-default">
          <div className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-a8a7167">
            <div className="elementor-widget-wrap elementor-element-populated">
              <div className="elementor-element elementor-element-83f9b79 elementor-widget elementor-widget-ashley-cf7-form">
                <div className="elementor-widget-container">
                  <div
                    className="wpcf7 no-js"
                    id="wpcf7-f546-p222-o1"
                    lang="en-US"
                    dir="ltr"
                  >
                    <div className="screen-reader-response">
                      <p role="status" aria-live="polite" aria-atomic="true" />
                      <ul />
                    </div>
                    <Form
                      action="https://ashley.bslthemes.com/contact/#wpcf7-f546-p222-o1"
                      method="post"
                      className="wpcf7-form init"
                      aria-label="Contact form"
                      noValidate="novalidate"
                      data-status="init"
                    >
                      <div className="row align-items-center">
                        <div className="col-lg-6 mil-up">
                          {" "}
                          <span
                            className="wpcf7-form-control-wrap"
                            data-name="cf_name"
                          >
                            <Input
                              onChange={handleChange}
                              value={info.cf_name}
                              size={40}
                              maxLength={80}
                              className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                              aria-required="true"
                              aria-invalid="false"
                              placeholder="What's your name"
                              defaultValue=""
                              type="text"
                              name="cf_name"
                            />
                          </span>
                        </div>
                        <div className="col-lg-6 mil-up">
                          {" "}
                          <span
                            className="wpcf7-form-control-wrap"
                            data-name="cf_email"
                          >
                            <Input
                              onChange={handleChange}
                              size={40}
                              maxLength={80}
                              className="wpcf7-form-control wpcf7-email wpcf7-validates-as-required wpcf7-text wpcf7-validates-as-email"
                              aria-required="true"
                              aria-invalid="false"
                              placeholder="Your Email"
                              defaultValue=""
                              type="email"
                              name="cf_email"
                              value={info.cf_email}
                            />
                          </span>
                        </div>
                        <div className="col-lg-12 mil-up">
                          {" "}
                          <span
                            className="wpcf7-form-control-wrap"
                            data-name="cf_message"
                          >
                            <textarea
                              onChange={handleChange}
                              cols={40}
                              rows={10}
                              maxLength={400}
                              className="wpcf7-form-control wpcf7-textarea wpcf7-validates-as-required"
                              aria-required="true"
                              aria-invalid="false"
                              placeholder="Tell us about your query"
                              name="cf_message"
                              defaultValue={""}
                              value={info.cf_message}
                            />
                          </span>
                        </div>
                        <div className="col-lg-8">
                          <p className="mil-up mil-mb-30">
                            <span className="mil-accent">*</span> We promise not
                            to disclose your personal information to third
                            parties.
                          </p>
                        </div>
                        <div className="col-lg-4">
                          <div className="mil-adaptive-right mil-up mil-mb-30">
                            {" "}
                            <button
                              onClick={handleSubmit}
                              type="submit"
                              className="mil-button mil-arrow-place"
                            >
                              {" "}
                              <span>Send message</span>{" "}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                class="mil-arrow"
                              >
                                <path d="M 14 5.3417969 C 13.744125 5.3417969 13.487969 5.4412187 13.292969 5.6367188 L 13.207031 5.7226562 C 12.816031 6.1136563 12.816031 6.7467188 13.207031 7.1367188 L 17.070312 11 L 4 11 C 3.448 11 3 11.448 3 12 C 3 12.552 3.448 13 4 13 L 17.070312 13 L 13.207031 16.863281 C 12.816031 17.254281 12.816031 17.887344 13.207031 18.277344 L 13.292969 18.363281 C 13.683969 18.754281 14.317031 18.754281 14.707031 18.363281 L 20.363281 12.707031 C 20.754281 12.316031 20.754281 11.682969 20.363281 11.292969 L 14.707031 5.6367188 C 14.511531 5.4412187 14.255875 5.3417969 14 5.3417969 z"></path>
                              </svg>
                            </button>
                          </div>
                        </div>
                      </div>
                      <div
                        className="wpcf7-response-output"
                        aria-hidden="true"
                      />
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactUsIndex;
